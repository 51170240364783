import React from 'react'
import Img from 'gatsby-image'

const BlogHeader = ({title, date, image, altText="",category}) => {
    return(

        <header className="bg-yellow-200 sm:min-h-70 min-h-50 pt-16">
                <div className="max-w-screen-xl mx-auto ">
                    <div className=" w-11/12 px-12 ">
                        <h1>{title}</h1>
                        <p >{date} |  4 MINS READ</p>
                        
                    </div>
                    <div className="sm:w-11/12 mx-auto">
                        <div className="relative">
                            <Img fluid={image} alt={altText} className="w-full sm:min-h-60 min-h-30 sm:max-h-60 max-h-30 object-cover object-center absolute block" />
                            <span className="sm:inline-block hidden uppercase absolute tracking-wide left-2 top-2 bg-torq-100 px-3">{category}</span>
                        </div>
                            
                    </div>
                
            </div>
            
        </header>
    )
}

export default BlogHeader