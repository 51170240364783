import { Link } from 'gatsby'
import React from 'react'

const RelatedPost = ({image, altText, category, title, slug, date}) => {
    return(
        <article className="flex sm:flex-row flex-col sm:w-5/12 justify-between items-center">
            <header className="relative sm:w-6/12">
                <img src={image} alt={altText} className="h-36 w-full object-cover" />
                <span className="inline-block absolute top-2 -right-4 uppercase text-sm tracking-wide px-2 bg-torq-100">{category}</span>
            </header>
            <content className="block sm:w-5/12">
                <Link to={`/blog/${slug}`} ><h3 className="text-torq-200 font-display mb-2">{title}</h3></Link>
                <p className="uppercase text-base">{date}</p>
            </content>

        </article>
    )
}

export default RelatedPost