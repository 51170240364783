import React from 'react'
import SharePostButton from './share'

const BlogContent = ({content, title, url}) => {
    return(
        <>
        {/* <div className="sm:min-h-30 min-h-10"></div> */}
        <div className="max-w-screen-xl mx-auto  sm:pt-12 pt-8 post-single flex sm:flex-row flex-col-reverse">
            <content  className="px-12 sm:w-9/12 block" dangerouslySetInnerHTML={{__html: content}}></content>
            <section className="sm:w-3/12 block">
                <SharePostButton title={title} url={url} />
            </section>
        </div>
        </>
    )
}

export default BlogContent