import React, { useState } from 'react'
import TwitterLogo from '../../../images/icons/share/twitter.png'
import FacebookLogo from '../../../images/icons/share/facebook.png'
import LinkedIn from '../../../images/icons/share/linkedin.png'
import Mail from '../../../images/icons/share/email.png'
import WhatsApp from '../../../images/icons/share/whatsapp.png'
import Share from '../../../images/icons/share/share.png'


const SharePostButton = ({title, url}) => {

    const [iconsOpened, setIconsOpened] = useState(false)

    const popupWindow = (link) => {
        window.open(link,'popup','width=600,height=600')
    }

    return (
        <div className="transform sm:sticky relative top-0 sm:pt-24 pb-16 sm:scale-100 scale-75 sm:mt-0 -mt-10 border-blue sm:pr-8 pr-0 flex justify-end  items-center ">


            <button 
            className={(iconsOpened && `-translate-x-24 translate-y-12 opacity-100`)+` transform  share-icon` }
            href="#" onClick={()=>popupWindow(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)}>
                
                    <img src={LinkedIn} alt="LinkedIn logo" className="w-6 inline-block mb-0"/>
                </button>
        

            <button className={(iconsOpened && `-translate-x-28 -translate-y-2 opacity-100 `)+` transform  share-icon` } href="#" onClick={()=>popupWindow(`https://twitter.com/intent/tweet?text=${title}&url=${url}&via=TGIC`)}>
                
                    <img src={TwitterLogo} alt="Twitter logo" className="w-6 inline-block mb-0"/>
                </button>

            <button className={(iconsOpened && `-translate-x-20 -translate-y-14 opacity-100  `)+` transform  share-icon` } href="#" onClick={()=>popupWindow(`http://www.facebook.com/share.php?u=${url}`)}>
                
                    <img src={FacebookLogo} alt="Facebook logo" className="w-6 inline-block mb-0"/>
                </button>
         
                <a className={(iconsOpened && `-translate-x-6 -translate-y-20 opacity-100  `)+` transform  share-icon` } href={`mailto: ?subject=${title}&body=${url}`} >
                
                <img src={Mail} alt="Email" className="w-6 inline-block mb-0"/>
            </a>

            <a className={(iconsOpened && `translate-x-8 -translate-y-14 opacity-100 `)+` transform  share-icon` } href={`https://api.whatsapp.com/send?text=Read this article on ${title} ${url} by The Green Investment Club`} target="_blank" rel="noreferrer">
                
                <img src={WhatsApp} alt="WhatsApp" className="w-6 inline-block mb-0"/>
            </a>


        





            <button 
            className="h-24 w-24 absolute   rounded-full bg-white shadow-share flex items-center justify-center transform transition-transform hover:scale-75"
            onClick={()=>(setIconsOpened(!iconsOpened))}
            >
                <img src={Share} alt="Share Icon" className="w-10 inline-block mb-0"/>
            </button>


        </div>

        
    )
}

export default SharePostButton