import React from 'react'
import RelatedPost from './related-post'


const RelatedPosts = ({relatedPosts}) => {
    const allRelatedPosts = relatedPosts.map(relatedPost => 
    <RelatedPost 
        title={relatedPost.node.title}
        category={relatedPost.node.categories.nodes[0].name}
        image={relatedPost.node.featuredImage.node.localFile.publicURL}
        altText={relatedPost.node.featuredImage.node.altText}
        date={relatedPost.node.date}
        slug={relatedPost.node.slug}
         />)

    
    return(
        <section className="py-16 bg-torq-100 mt-32 px-12">
            <section className="max-w-screen-xl mx-auto">
                <h2 className="">Read other posts.</h2>
                <section className="flex justify-between sm:flex-row flex-col mt-8">
                    {allRelatedPosts}
                </section>
            </section>
            
        </section>
    )
}

export default RelatedPosts