import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../../layout'
import SEO from '../../seo'
import RelatedPosts from '../related'
import BlogContent from './content'

import BlogHeader from "./header"

export const postQuery = graphql `
    query ($slug: String!) {
        wpPost(slug: {eq: $slug}) {
          title
          uri
          content
          date(formatString: "DD MMMM, YYYY")
          categories{
            nodes{
              name
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp{
                  fluid{
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }

        allWpPost(limit: 2, filter: {slug: {ne: $slug}}) {
            edges {
              node {
                id
                title
                slug
                featuredImage{
                  node {
                    altText
                    localFile{
                      publicURL
                    }
                  }
                }
                date(formatString: "DD MMMM, YYYY")
                categories {
                  nodes {
                    name
                  }
                }
              }
            }
          }

      }

      
      
    `

const BlogPost = ({data}) => {

    
    const post = data.wpPost
    const relatedPosts = data.allWpPost.edges
    const featuredImage = post.featuredImage.node
        return(

            <Layout color="yellow">
                <SEO title={post.title} />
                <BlogHeader 
                title={post.title} 
                image={featuredImage.localFile.childImageSharp.fluid}
                altText={featuredImage.altText}
                date={post.date}
                category={post.categories.nodes[0].name}
                 />
                 <BlogContent content={post.content} title={post.title} url={`https://thegreeninvestmentclub.com/blog/${post.uri}`} />
                 <RelatedPosts relatedPosts={relatedPosts} />
               
            </Layout>
            
        )
}

export default BlogPost